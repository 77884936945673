import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { navigate } from 'gatsby'

import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { paths } from 'routes'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	roleContainer: {
    paddingBottom: theme.spacing(4),
	},
  container: {
    padding: '48px 36px',
  },
  storeWrapper: {
    // padding: theme.spacing(1)
  },
  buttonContainer: {
    padding: theme.spacing(1),
  }
}))

const HomeContent = ({ auth, labeling, setListDb, fetchLabelerContext, toggleSubmissionLock, setTeamSize, setTeamMemberId}) => {
	const classes = useStyles()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    (async () => {
      await fetchLabelerContext()
      setLoading(false)
      if (labeling.stores.length === 1) {
        setListDb(labeling.stores[0].store)
        navigate('/list')
      }
    })()
  }, [])

  useEffect(() => {
    let interval = null
    interval = setInterval(() => {
      console.log(labeling.stores, labeling.currentDatabase);
      if (labeling.stores.length === 1) {
        setListDb(labeling.stores[0].store)
        navigate('/list')
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [labeling.stores])

  const stores = labeling.stores.map((st) => st.store.replace('dieta-stool-training-', '').replace('data-', ''));

	return (
    <>
    {loading || labeling.stores.length === 1 ? (
      <div className={classes.container}>
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      </div>
    ) : (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={classes.roleContainer} variant="h5" color="textSecondary" component="p">
              Please select a labeling datastore from the list below to get started
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={12} xs={12}>
            <Paper>
              <Grid container spacing={4}>
                {stores.map((store, i) => {
                    return (
                      <Grid item key={i} sm={4} xs={12}>
                        <div className={classes.buttonContainer}>
                          <Button variant="contained" color="primary" onClick={() => {
                            toggleSubmissionLock(false)
                            setTeamSize(1)
                            setTeamMemberId(1)
                            setListDb(labeling.stores[i].store)
                            navigate('/list')
                          }}>
                            <div className={classes.storeWrapper}>
                              <Typography variant="body2" component="p">
                                {store}
                              </Typography>
                            </div>
                          </Button>
                        </div>
                      </Grid>
                    )
                  })}
              </Grid>

            </Paper>
          </Grid>
        </Grid>
      </div>
    )}
		</>
	)
}

HomeContent.propTypes = {
  auth: PropTypes.shape({}),
  labeling: PropTypes.shape({
    characteristics: PropTypes.array,
    currentDatabase: PropTypes.string,
    profile: PropTypes.shape({
      role: PropTypes.string,
    }),
    stores: PropTypes.array
  }),
  fetchLabelerContext: PropTypes.func.isRequired,
  setListDb: PropTypes.func.isRequired,
  toggleSubmissionLock: PropTypes.func.isRequired,
  setTeamSize: PropTypes.func.isRequired,
  setTeamMemberId: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, labeling }) => {
	return {
		auth,
    labeling,
	}
}

export default connect(mapStateToProps, actions)(HomeContent)
